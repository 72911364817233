<template>
  <Row class="page5">

    <Col style="width:50%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="classifyExplain">{{classifyExplain}}</span>
          <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:50%;height: 95%;display: flex;flex-direction: column">
     <div style="width: 100%;height: 50%;display: flex;">
       <div class="top-left-list">
         <span class='title'><span class="title-text">{{picsTitle}}</span></span>
         <span class="angle1"></span>
         <span class="angle2"></span>
         <span class="angle3"></span>
         <span class="angle4"></span>
         <area-chart style="height: 55%" ref="chart14" :config="configData9" :choiceIndex="5"></area-chart>
         <colors-bar ref="chart15"></colors-bar>
       </div>
       <div class="top-right-list">
         <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
         <span class="angle1"></span>
         <span class="angle2"></span>
         <span class="angle3"></span>
         <span class="angle4"></span>
         <funnel ref="chart16"></funnel>
       </div>
     </div>
      <div class="center-bottom" style="display:flex;">
          <div class="bottom-left">
            <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <workPics ref="workPic" :isBig="true"></workPics>
          </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">{{photoTitle}}</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in classify" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item.classifyName}}</div>
    </div>
  </Row>
</template>

<script>
import {getDataApi, totalDataApi,getHonorData} from "@/api/dataList";
// import {handleOldNewArrFn} from "@/lib/oldNewArr";

const chinaMap  = () => import('./components/chinaMap');
const areaChart = ()=> import('./components/areaChart');
const funnel = () => import('./components/page1/funnel');
const colorsBar = ()=> import('./components/page6/colorsBar');
const workPics = () => import('./components/page2/workPictures');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'page6',
  components: {
    chinaMap,
    funnel,
    areaChart,
    colorsBar,
    workPics,//项目图片
    honorPics, //荣誉图片
  },
  data() {
    return {
      //tabNav子菜单
      classify:[],
      chooseIndex:0,
      photoTitle:'湖湘公益汇',
      picsTitle:'', //右下角图片标题
      //地图展示项目数据
      resdata:[],
      //项目图片数组
      workPicsList:[],
      //右上角聊天数据
      chatRadarData: {
        title: '各部门聊天对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [
          {text: '一部'},
          {text: '二部'},
          {text: '三部'},
          {text: '四部'},
          {text: '五部'},
          {text: '六部'}
        ],
        data: [
          {
            name: '聊天次数',
            color: '#0DF5F8',
            value: [100, 8, 0.40, -80, 2000, 332],
          },
          {
            name: '聊天人数',
            color: '#7921AD',
            value: [60, 5, 0.30, -100, 1500, 221],
          }
        ]
      },
      cnfigData1: {
        color: '#5CB1C1',
        name: ['（次）', '（人）'],
        data: [
          {
            name: '聊天次数',
            color: ['#9e70ff', '#6e5eff'],
            data: [200, 12, 21, 54, 260, 130, 210],
          },
          {
            name: '聊天人数',
            color: ['#48cefd', '#5356f1'],
            data: [50, 182, 234, 191, 190, 30, 10],
          }
        ]
      },
      // 交友分析面积图
      configData9: {
        title: '【项目数据】',
        color: '#75deef',
        name: ['（个）', '（个）'],
        data: [
          {
            name: '活动场次',
            color: ['#feed2c', '#353103'],
            data: []
          },
          {
            name: '社会组织参与数',
            color: ['#2871ea', '#0a1b41'],
            data: []
          },
          {
            name: '志愿者人数',
            color: ['#935adf', '#230f3e'],
            data: []
          },
          {
            name: '覆盖学校数量',
            color: ['#e65f2d', '#551f0b'],
            data: []
          }
        ]
      },
      resizeFn: null,
      classifyExplain:'',
      vedioShow:false
    }
  },
  created() {
    // this.getData();
  },
  methods: {
    // 获取数据
    async getData() {
      const res = await totalDataApi(17);
      this.classify = res.data.classify;
      this.chooseNav(this.classify[0],0);
    },
    //  点击子菜单选择
    async chooseNav(item,index){
      const res = await getDataApi(item.classifyId);
      this.resdata = res.rows;
      this.chooseIndex = index;
      this.classifyExplain = item.classifyExplain //简介
      this.picsTitle =  item.classifyName; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = item.img;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false; //加载中
      // 荣誉图片或者荣誉视频部分
      const HonRes = await getHonorData(item.classifyId)
      if(HonRes.code == 200) {
        console.log(HonRes);
        if(HonRes.data.switchOption==1){
          //荣誉图片
          this.$refs.honor.shows = 1
          this.photoTitle = '荣誉图片'
           this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
           this.$refs.honor.honoraryPicsList = item.honorImage;
        }else if(HonRes.data.switchOption==2){
          //精彩视频
          this.$refs.honor.shows = 2
          if(HonRes.data.videoUrl){
            HonRes.data.videoUrl = JSON.parse(HonRes.data.videoUrl)
          }
          this.photoTitle = HonRes.data.videoUrl[0].title||'精彩视频'
          console.log( this.photoTitle,'title');
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL = []
          this.$refs.honor.videoUrl = HonRes.data.videoUrl[0].url;
         
        }else if(HonRes.data.switchOption==3) {
          //精彩图片
          this.$refs.honor.shows = 3
          if(HonRes.data.photoUrl){
            HonRes.data.photoUrl = JSON.parse(HonRes.data.photoUrl)
          }
          this.photoTitle = '湖湘公益汇'
          this.$refs.honor.videoUrl = '';
          this.$refs.honor.honoraryPicsList = []
          this.$refs.honor.perfectPicsListL =  HonRes.data.photoUrl
        }
      }else {
        this.$refs.honor.honoraryPicsList = [];
        this.$refs.honor.videoUrl = ''
           this.$refs.honor.perfectPicsListL = []
      }
      this.$refs.honor.honoraryPicsList = item.honorImage;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i=0;i<this.resdata.length;i++){
        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[0].attent) + Number(this.resdata[i].list[0].money)
            + Number(this.resdata[i].list[0].schoolCount) + Number(this.resdata[i].list[0].serviceCount)
            + Number(this.resdata[i].list[0].soprtcount) + Number(this.resdata[i].list[0].volunteerCount)
        this.resdata[i].value = totalValue;
      }

      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      console.log(this.$refs.chart15,'this.$refs.chart15')
      this.$refs.chart15.moneyList = [];
      this.$refs.chart14.config.data[0].data = [];
      this.$refs.chart14.config.data[1].data = [];
      this.$refs.chart14.config.data[2].data = [];
      this.$refs.chart14.config.data[3].data = [];
      this.$refs.chart16.serviceList=[];

      //各城市的数据遍历获取
      this.resdata.forEach(it=>{
        this.$refs.chart14.config.data[0].data.push(it.list[0].soprtcount) //活动场次
        this.$refs.chart14.config.data[1].data.push(it.list[0].attent) //社会组织参与数
        this.$refs.chart14.config.data[2].data.push(it.list[0].volunteerCount) //志愿者人数
        this.$refs.chart14.config.data[3].data.push(it.list[0].schoolCount) //覆盖学校数量
        this.$refs.chart15.moneyList.push(it.list[0].money) //投入款物
        this.$refs.chart16.serviceList.push(it.list[0].serviceCount) //右边服务人次
      })
      //刷新子组件数据
      this.$refs.chart15.setChart();
      this.$refs.chart14.setChart();
      this.$refs.chart16.setChart();
    },
  },
  mounted() {
    this.getData();
    this.resizeFn = this.$debounce(()=> {
      this.$refs.chinaMap.setMap();
      this.$refs.chart15.setChart();
      this.$refs.chart14.setChart();
      this.$refs.chart16.setChart();
    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 14px 12px 0;
  //padding: 8px 10px 18px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    cursor: pointer;
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 42%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }
  .top-right-list{
    margin-left: 2%;
  }

  .top-left-list,.top-right-list,.left,.description,.bottom-right,.bottom-left{
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    //background:rgb(8,25,79);
    box-sizing: border-box;
    height: 95%;
    width:100%;

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .left{
    height: 72%!important;
    background: none!important;
    border: none!important;
  }
  .description{
    background: none!important;
    height: 25%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
    margin-bottom: -1%;
    //width: 105%!important;;
  }
  .describeList{
    text-indent: 2%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #a1b3dc;
  }
  .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }
  .center-bottom {
    width: 100%!important;
    height: 48%!important;
    margin-top: 2%;

    .bottom-left,.bottom-right {
      width: 50%;
      height: 100%;
      position: relative;
      #left1, #left2, #left3, #right1, #right2, #right3, #center2 {
        height: 100%;
      }

      .chart-68 {
        width: 68%;
        height: 100%;
        float: left;
      }

      .chart-32 {
        width: 32%;
        height: 100%;
        float: left;
      }
    }
  }
  }

</style>
